@import "../../ui/styleUtils/index.scss";
@import "../../variables.scss";

.searchControls {
  padding: $sizeL $sizeXxl * 2 0;
}

@media screen and (max-width: $breakpointContentIsCramped) {
  .searchControls {
    padding: $sizeL $sizeXxl 0;
  }
}
